#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--assets-font-family-body);
  line-height: 1.15;
  background-color: var(--color-background-secondary);
  font-size: 16px;
  color: var(--color-font-base);
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scroll-bar-thin {
  scrollbar-width: thin;
  scrollbar-color: var(--color-border-default) transparent;
}

.truncate {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

a,
a:visited {
  color: var(--color-font-base);
}

a.font-color-secondary,
a.font-color-secondary:visited {
  color: var(--color-font-secondary);
}

a:hover {
  text-decoration: underline;
}

[role='menuitem']:hover {
  text-decoration: none;
}

.navlink {
  transition: background-color 0.3s ease;
}

.navlink,
.navlink:visited,
.navlink:hover {
  text-decoration: none;
}

.navlink.active,
.navlink.active > div {
  background-color: var(--color-background-tertiary);
}

#mainNav {
  bottom: 0;
  top: 0;
  transform: translateX(-260px);
  transition: transform 0.3s ease-in-out;
  z-index: var(--size-z-index-drawer);
}

@media screen and (min-width: 992px) {
  #mainNav {
    position: relative;
    transform: translateX(0);
  }
}

@property --p {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 10%;
}

.first-run-hero-bg {
  background: linear-gradient(0deg, var(--color-brand-orange) var(--p), var(--color-background-primary) 50%);
  animation: fadeBackground 2s forwards;
}

.skeletonBox {
  position: relative;
  overflow: hidden;
}

.skeletonBox::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  content: '';
}

@media screen and (min-width: 680px) {
  .arrow-box:after,
  .arrow-box:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 1;
  }

  .arrow-box:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #ffffff;
    border-width: 8px;
    margin-top: -8px;
  }
  .arrow-box:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: var(--color-base-grey-100);
    border-width: 10px;
    margin-top: -10px;
  }
}

.row-item:first-of-type {
  border-top-color: transparent;
}

ol.steps-list {
  padding: 0 0 0 40px;
  list-style: none;
  overflow: hidden;
  counter-reset: numList;
}
ol.steps-list > li {
  position: relative;
}
.steps-list > li:before {
  counter-increment: numList;
  content: counter(numList);

  float: left;
  position: absolute;
  left: -40px;

  font-weight: var(--size-font-weight-semibold);
  font-size: var(--size-font-size-sm);
  text-align: center;
  color: var(--color-font-inverse);
  line-height: var(--size-dimension-3xl);

  width: var(--size-dimension-3xl);
  height: var(--size-dimension-3xl);
  background: var(--color-background-inverse);

  -moz-border-radius: 999px;
  border-radius: 999px;
}

.steps-list p {
  margin: 0 0 var(--size-spacing-xl) 0;
}

.capitalize {
  text-transform: capitalize;
}

.text-overflow-clamp {
  width: 100%;
  /* this code clamps based on specified lines */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
}

.link-hovered {
  cursor: pointer;
  text-decoration: none;
}

.link-hovered:hover {
  text-decoration: underline;
}

pre {
  overflow-x: auto;
}

code {
  display: inline-block;
  background: var(--color-background-secondary);
  padding: 0 var(--size-spacing-xs);
  color: var(--color-font-danger);
  border-radius: var(--size-border-radius-sm);
  font-family: var(--assets-font-family-monospace);
  border: var(--size-border-width-sm) solid var(--color-border-subtle);
}

.frosted-glass {
  background: linear-gradient(300deg, rgba(255, 255, 255, 0.05) 22%, rgba(255, 255, 255, 0.6) 75%);
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.dark .frosted-glass {
  background: linear-gradient(300deg, rgba(255, 255, 255, 0.05) 22%, rgba(255, 255, 255, 0.15) 75%);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.tab-nav,
.tab-nav:visited {
  color: var(--color-font-secondary);
  text-decoration: none;
}

.tab-nav:hover {
  color: var(--color-font-primary);
  text-decoration: none;
}

.tab-nav.active {
  background-color: var(--color-background-primary);
  border-radius: var(--size-border-radius-sm);
  box-shadow: var(--size-box-shadow-xs);
  color: var(--color-font-primary);
}

.loading-mask {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 50%);
  z-index: 10;
}

input[type='color'] {
  padding: 0;
  background-color: transparent;
  border: 1px solid var(--color-border-subtle);
  width: var(--size-dimension-4xl);
  height: var(--size-dimension-4xl);
  color: var(--color-font-base);
  border-radius: var(--size-border-radius-sm);
}

input[type='color']::-moz-color-swatch {
  border: none;
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
  border: none;
}
input[type='color']::-webkit-color-swatch {
  border: none;
}

.upload-label {
  background-color: var(--color-background-button-secondary);
  color: var(--color-font-button-secondary);
  border: 1px solid var(--color-border-button-secondary);
  padding: var(--size-spacing-sm) var(--size-spacing-md);
  font-size: var(--size-font-size-sm);
  border-radius: var(--size-border-radius-sm);
  width: fit-content;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-button-secondary-hover);
    color: var(--color-font-button-secondary-hover);
    border: 1px solid var(--color-border-button-secondary-hover);
  }
}

.upload-input {
  display: none;
}

.error-message {
  font-size: var(--size-font-size-sm);
  color: var(--color-font-danger);
}

.update-card-highlight {
  animation: update-card-highlight 2s ease-out;
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.markdown-renderer {
  h2 {
    font-size: var(--size-font-size-lg);
    margin-top: var(--size-spacing-lg);
  }
  h2:first-of-type {
    margin-top: 0;
  }
  pre {
    border-radius: var(--size-border-radius-xl);
    border: 0.0625rem solid #40404080;
    margin-top: 0 !important;
    margin-bottom: var(--size-spacing-lg) !important;
  }
  code {
    border: none;
    font-size: var(--size-font-size-xs) !important;
  }

  ol {
    padding-left: var(--size-spacing-md);
  }

  li {
    line-height: var(--size-line-height-heading);
    padding-bottom: var(--size-spacing-xs);
  }

  ul {
    margin-bottom: var(--size-spacing-md);
  }

  table {
    margin-bottom: var(--size-spacing-lg);
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    white-space: normal;
    thead,
    tr {
      border-color: var(--color-border-subtle);
    }
    tr {
      border-bottom-style: solid;
    }
    td,
    thead th {
      padding: var(--size-spacing-md) var(--size-spacing-2xl);
    }
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@keyframes fadeBackground {
  from {
    --p: -1000%;
  }

  to {
    --p: -150%;
  }
}

@keyframes update-card-highlight {
  0% {
    background-color: var(--color-background-primary);
    box-shadow: 0 0 0 var(--color-brand-blue-300);
  }

  50% {
    background-color: var(--color-background-info);
    box-shadow: 0 0 32px var(--color-base-blue-300);
  }
  100% {
    background-color: var(--color-background-primary);
    box-shadow: 0 0 0 var(--color-brand-blue-300);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.opacity-0 {
  opacity: 0;
}

.group {
  position: relative;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.transition-opacity {
  transition: opacity 0.3s linear;
}
